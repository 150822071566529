import React from 'react';
import Layout from '../../layouts/index';
import OneOfShibuyaCrossStreetImg from '../../images/one-of-shibuya-cross-street.jpg'
import HealthScreeningCompanyImg from '../../images/health-screening-company.jpg'
import ValueVsCostImg from '../../images/value-vs-cost.jpg'
import MedicalDataImg from '../../images/medical-data.jpg'
import SincerityImg from '../../images/sincerity.jpg'
import TimeWithOperationImg from '../../images/time-with-operation.jpg'


const ForCompaniesContent = () => (
  <div className="container sub-content">
    <h1>健康診断をお考えの企業様へ</h1>
    <div>
      <div className="row box">
        <div className="col-sm-4">
          <img
            className="img-with-text img-responsive center-block img-thumbnail"
            src={OneOfShibuyaCrossStreetImg}
            alt=""
          />
        </div>
        <div className="col-sm-8">
          <h3>利便性</h3>
          <p>
            当院は渋谷駅から徒歩３分。明治通りを原宿方面に歩けば左側にあり、迷うことがありません。
          </p>
        </div>
      </div>
    </div>
    <div>
      <div className="row box">
        <div className="col-sm-4">
          <img
            className="img-with-text img-responsive center-block img-thumbnail"
            src={TimeWithOperationImg}
            alt=""
          />
        </div>
        <div className="col-sm-8">
          <h3>スピード</h3>
          <p>
            通常の企業検診では１時間以内、簡単なレントゲンなどのない健診であればできるだけ30分以内にお帰りいただけるよう、常に心がけて行っております。
            <br />
            検診前後の移動時間、検診の間も人件費はかかりますので、スピードは重要と考えております。
          </p>
        </div>
      </div>
    </div>
    <div>
      <div className="row box">
        <div className="col-sm-4">
          <img
            className="img-with-text img-responsive center-block img-thumbnail"
            src={HealthScreeningCompanyImg}
            alt=""
          />
        </div>
        <div className="col-sm-8">
          <h3>豊富な実績</h3>
          <p>
            周辺の少人数の企業様をはじめ、多くの大手企業様にもご利用いただいており、現在企業検診は年に数千件を数えております。
            <br />
            当院は開業して２０年余。豊富な企業検診の実績があります。
          </p>
        </div>
      </div>
    </div>
    <div>
      <div className="row box">
        <div className="col-sm-4">
          <img
            className="img-with-text img-responsive center-block img-thumbnail"
            src={ValueVsCostImg}
            alt=""
          />
        </div>
        <div className="col-sm-8">
          <h3>リーズナブルな料金</h3>
          <p>
            鼻からの胃カメラをバリウム検査と同等の料金で提供するなど、他院様よりもリーズナブルな料金で検査をさせていただいております。
          </p>
        </div>
      </div>
    </div>
    <div>
      <div className="row box">
        <div className="col-sm-4">
          <img
            className="img-with-text img-responsive center-block img-thumbnail"
            src={MedicalDataImg}
            alt=""
          />
        </div>
        <div className="col-sm-8">
          <h3>XML出力に対応</h3>
          <p>
            特定健診ではXML形式でのデータ提出が求められますが、当院でもXML形式での出力が可能です。
            <br />
            ご希望があれば、EXCEL形式での出力も可能です。
          </p>
        </div>
      </div>
    </div>
    <div>
      <div className="row box">
        <div className="col-sm-4">
          <img
            className="img-with-text img-responsive center-block img-thumbnail"
            src={SincerityImg}
            alt=""
          />
        </div>
        <div className="col-sm-8">
          <h3>丁寧な検査</h3>
          <p>
            健診センターなどでの胃カメラ、超音波検査は非常に数が多く、流れ作業的な短時間の検査しかできないのが実情ですが、当院では枠を減らすことでたっぷりと検査時間をご用意しています。
            <br />
            大雑把な検査ではなく、見逃しの少ない、しっかりとした検査を提供しています。
            <br />
            （例：一般的な健診センターでの腹部エコー検査は６～７分程度。当院では１０～２０分かけてしっかり観察します。）
          </p>
        </div>
      </div>
    </div>
    <div>
      <p>
        年1回の定期健診でご受診の企業には、当日医師の判断で追加項目検査を実施する可能性があり、その追加分を請求させていただきますのでご了承ください。
        <br />
        参考資料：
        <a href="https://www.mhlw.go.jp/file/06-Seisakujouhou-11200000-Roudoukijunkyoku/0000194626.pdf">
          厚生労働省労働基準局「定期健康診断等における診断項目の取扱い等について」PDF
        </a>
      </p>
    </div>
  </div>
);

const ForCompanies = () => (
  <Layout>
    <ForCompaniesContent />
  </Layout>
);

export default ForCompanies;
